<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Exams
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-history-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-history"
          type="button"
          role="tab"
          aria-controls="pills-history"
          aria-selected="false"
        >
          Assignments
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <webinar-loader v-if="loader == true"></webinar-loader>
        <div class="table-responsive" v-else>
          <table class="table">
            <tr>
              <th>Sl No</th>
              <th>Exam</th>
              <th>Date</th>
              <th>Subject</th>
              <th>Status</th>
            </tr>
            <tr v-for="(exam, examIndex) in exams" :key="examIndex">
              <td>{{ examIndex + examCountFrom }}</td>
              <td>{{ exam.title }}</td>
              <td>{{ exam.exam_date }}</td>
              <td>
                <span
                  v-for="(subjects, examSubjectsIndex) in exam.subjects"
                  :key="examSubjectsIndex"
                >
                  {{ subjects.title }}&#44;</span
                >
              </td>
              <td>
                <router-link
                  v-if="exam.status == 'LIVE'"
                  class="btn cta-mini"
                  :to="{ name: 'exam', params: { id: exam.id } }"
                >
                  Attend
                </router-link>
                <span v-if="exam.status == 'PUBLISHED'">
                  Not Started
                </span>
                <router-link
                  v-if="exam.status == 'ENDED'"
                  class="btn cta-mini"
                  :to="{ name: 'examResult', params: { id: exam.id } }"
                >
                  View Result
                </router-link>
              </td>
            </tr>
          </table>
        </div>

        <div class="empty" v-if="exams.length == 0 && loader == false">
          No exam found
        </div>

        <div class="paging-wrapper">
          <v-pagination
            v-model="examsPage"
            :pages="examTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="examChangeHandler"
            v-if="totalExams > 0"
          />
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-history"
        role="tabpanel"
        aria-labelledby="pills-history-tab"
      >
        <div>
          <webinar-loader v-if="loader == true"></webinar-loader>
          <div class="table-responsive" v-else>
            <table class="table">
              <tr>
                <th>Sl No</th>
                <th>Assignment</th>
                <th>Subject</th>
                <th>Last Date</th>
                <th>Status</th>
              </tr>
              <tr
                v-for="(assignment, assignmentIndex) in assignments"
                :key="assignmentIndex"
              >
                <td>{{ assignmentIndex + assignmentCountFrom }}</td>
                <td>{{ assignment.title }}</td>
                <td>{{ assignment.subject }}</td>
                <td>{{ assignment.last_date }}</td>
                <td>
                  <router-link
                    class="btn cta-mini"
                    :to="{ name: 'assignments', params: { id: assignment.id } }"
                  >
                    Attend
                  </router-link>
                </td>
              </tr>
            </table>
          </div>

          <div class="empty" v-if="assignments.length == 0 && loader == false">
            No assignment found
          </div>
        </div>
        <div class="paging-wrapper">
          <v-pagination
            v-model="assignmentsPage"
            :pages="assignmentsTotalPages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="assignmentChangeHandler"
            v-if="totalAssignments > 0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import "vue3-pagination/dist/vue3-pagination.css";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import VPagination from "vue3-pagination";
import AssignmentService from "@/services/AssignmentService.js";
import ExamService from "@/services/ExamService";

export default {
  name: "AcademicTab",
  components: {
    WebinarLoader,
    VPagination
  },
  data() {
    return {
      exams: [],
      assignments: [],
      loader: true,
      examsPage: 1,
      assignmentsPage: 1,
      examTotalPages: 0,
      totalExams: 0,
      assignmentsTotalPages: 0,
      totalAssignments: 0,
      examCountFrom: 1,
      assignmentCountFrom: 1
    };
  },
  created() {
    this.getExams();
    this.getAssignments();
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    async getExams() {
      await ExamService.getExams({ page: this.examsPage })
        .then(result => {
          this.exams = result.data.data;
          this.examTotalPages = result.data.last_page;
          this.totalExams = result.data.total;
          this.examCountFrom = result.data.from;
          this.loader = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async getAssignments() {
      await AssignmentService.getAssignments({ page: this.assignmentsPage })
        .then(result => {
          this.assignments = result.data.activeAssignments.data;
          this.assignmentsTotalPages = result.data.activeAssignments.last_page;
          this.totalAssignments = result.data.activeAssignments.total;
          this.assignmentCountFrom = result.data.activeAssignments.from;
          this.loader = false;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },

    examChangeHandler(selectedPage) {
      this.examsPage = selectedPage;
      this.getExams();
    },

    assignmentChangeHandler(selectedPage) {
      this.assignmentsPage = selectedPage;
      this.getAssignments();
    },
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
</style>
